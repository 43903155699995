import React from "react";
import SingleImagePage from "../../components/SingleImagePage";

const TrollPage = () => {
  return (
    <SingleImagePage
      imageUrl="/src/assets/troll.png"
      title="Free Animated Troll Face Emoji Maker"
      description="Create custom animated Troll Face emojis and emotes for Twitch, Discord, and Slack. Turn the classic trollface meme into animated emotes with shake, spin, party effects and more!"
      alt="The troll face meme"
    />
  );
};

export default TrollPage;
